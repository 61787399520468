import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const cita = ref(null);

    const getCita = async (id) => {
        const res = await axios.get(`/solicitud-cita/${id}`);
        if (res.status === 200) {
            cita.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        cita,
        getCita,
    };
}
