<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Tipo de cita</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.tipoCita" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Fecha de creación</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.fecha" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Preferencia de horario</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.preferencia" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Nombre del paciente</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.fullname" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Tipo de documento</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.documentType" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Número de documento</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.documentNumber" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Fecha de nacimiento</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.born" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Correo</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.email" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Teléfono</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.contact" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Póliza de salud</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.poliza" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Usa gafas</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.gafas" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Enfermedades</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.enfermedades" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Sintomas</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.sintomas" readonly />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-md-9">
                                        <SelectInputSimpleState v-model="form.state.status"></SelectInputSimpleState>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-12 text-end">
                                <div class="btn btn-primary ms-auto">
                                    <i class="far fa-save"></i>
                                    Guardar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script setup>
// eslint-disable-next-line object-curly-newline
import { defineProps, onMounted } from 'vue';

import WindowLayout from '@/layouts/Window.layout.vue';

import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';

import useSolicitudCita from './services/useSolicitudCita';
import { useSolicitudCitaForm } from './composables/useSolicitudCitaForm';

const props = defineProps({
    title: { type: String, default: 'Solicitud de Cita' },
    headerTitle: { type: String, default: 'Revisar Solicitud' },
    urlRead: { type: String, required: false },
    urlCreate: { type: String, required: false },
    urlUpdate: { type: String, required: false },
    item: Object,
});

const service = useSolicitudCita();
const { ...form } = useSolicitudCitaForm();

onMounted(async () => {
    if (props.item) {
        await Promise.all([service.getCita(props.item.id)]);
        if (service.cita) {
            form.setState(service.cita.value);
        }
    }
});
</script>
