import { reactive, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line object-curly-newline
import { required, email } from '@vuelidate/validators';
import { DateTime } from 'luxon';

export const useSolicitudCitaForm = () => {
    const state = reactive({
        id: '',
        tipoCita: '',
        fecha: '',
        preferencia: '',
        fullname: '',
        documentType: '',
        documentNumber: '',
        born: '',
        email: '',
        contact: '',
        poliza: '',
        gafas: '',
        enfermedades: '',
        sintomas: '',
        status: 1,
    });

    const formatDate = (date) => {
        const formatedDate = computed(() => DateTime.fromISO(date).toFormat('dd LLL yyyy h:mm a', { locale: 'co' }));

        return formatedDate;
    };

    const setState = (c) => {
        state.id = c.id;
        state.tipoCita = c.tipoCita.name;
        state.fecha = formatDate(c.createdAt);
        state.fullname = c.fullname;
        state.documentNumber = c.infoPaciente.identification;
        state.born = c.infoPaciente.born;
        state.email = c.infoPaciente.email;
        state.contact = c.infoPaciente.contact;
        state.poliza = c.infoPaciente.poliza;
        state.enfermedades = c.infoPaciente.enfermedades;
        state.sintomas = c.infoCita.sintomas;
        state.status = c.status;

        switch (c.preferencia) {
            case 'A':
                state.preferencia = 'Temprano en la Mañana';
                break;

            case 'B':
                state.preferencia = 'Final de la mañana';
                break;

            case 'C':
                state.preferencia = 'Comienzo de la tarde';
                break;

            case 'D':
                state.preferencia = 'Final de la tarde';
                break;

            case 'E':
                state.preferencia = 'No importa';
                break;

            default:
                state.preferencia = 'No importa';
        }

        switch (c.infoPaciente.identification_type) {
            case 'CC':
                state.documentType = 'Cédula de Ciudadanía';
                break;
            case 'CE':
                state.documentType = 'Cédula de Extranjería';
                break;
            case 'PA':
                state.documentType = 'Pasaporte';
                break;
            case 'RC':
                state.documentType = 'Registro Civil';
                break;
            case 'TI':
                state.documentType = 'Tarjeta de Identidad';
                break;
            default:
                state.documentType = 'No importa';
        }

        switch (c.infoPaciente.gafas) {
            case 'N':
                state.gafas = 'No';
                break;
            case 'S':
                state.gafas = 'Si';
                break;
            default:
                state.gafas = 'No';
        }
    };

    const rules = computed(() => ({
        tipoCita: { required },
        fecha: { required },
        preferencia: { required },
        fullname: { required },
        documentType: { required },
        documentNumber: { required },
        born: { required },
        email: { required, email },
        contact: { required },
        poliza: { required },
        gafas: { required },
        enfermedades: { required },
        sintomas: { required },
    }));

    const $v = useVuelidate(rules, state);

    return {
        state,
        setState,
        $v,
        rules
    };
};

export default {};
